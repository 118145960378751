import { useState, useEffect } from "react";

import { Routes, Route, useSearchParams } from "react-router-dom";

import { CartProvider } from "./CartContext";

import Header from "./components/Header";
import Footer from "./components/Footer";

import LangingPage from "./pages/landing";
import TshirtPage from "./pages/tshirtSelection";
import AddressPage from "./pages/address";
import GiftSelectionPage from "./pages/giftSelection";

import { GlobalFonts } from "./fonts/fonts";
import { GlobalStyle, StyledFlexWrapper } from "./styles";

const App = () => {
  const [searchParams] = useSearchParams();
  const searchFirst = searchParams.get("fname");
  const [firstName, setFirstname] = useState();
  const searchLast = searchParams.get("lname");
  const [lastName, setLastname] = useState();

  useEffect(() => {
    setFirstname(searchFirst);
    setLastname(searchLast);
  }, []);

  const [tshirtSize, setTshirtSize] = useState("default");
  const handleTshirtSize = (size) => {
    const formattedSize = size.toLowerCase();
    setTshirtSize(formattedSize);
  };

  console.log(tshirtSize);

  return (
    <div className="App">
      <CartProvider handleTshirtSize={handleTshirtSize}>
        <GlobalStyle />
        <GlobalFonts />

        {/* <BrowserRouter> */}
        <Header />
        <StyledFlexWrapper>
          <Routes>
            <Route
              exact="true"
              path="/"
              element={<LangingPage firstName={firstName} />}
            />
            <Route
              path="/tshirt-selection"
              element={
                <TshirtPage
                  tshirtSize={tshirtSize}
                  handleTshirtSize={handleTshirtSize}
                />
              }
            />
            <Route
              path="/gift-selection"
              element={<GiftSelectionPage tshirtSize={tshirtSize} />}
            />
            <Route
              path="/address"
              element={
                <AddressPage
                  tshirtSize={tshirtSize}
                  firstName={firstName}
                  lastName={lastName}
                />
              }
            />
          </Routes>
        </StyledFlexWrapper>
        {/* </BrowserRouter> */}
      </CartProvider>
      <Footer />
    </div>
  );
};

export default App;
