import styled from "styled-components";

export const StyledHeader = styled.footer`
  padding: 48px 80px;

  @media (max-width: 1200px) {
    padding: 48px;
  }

  @media (max-width: 600px) {
    position: fixed;
    background: #fff;
    width: 100%;
    padding: 24px 16px;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;

  @media (max-width: 1440px) {
    width: 140px;
  }
`;
