import { createContext, useState, useEffect } from "react";

const CartContext = createContext();

export function CartProvider({ children, handleTshirtSize }) {
  const [showCart, setShowCart] = useState(false);
  const showHideCart = (view) => {
    setShowCart(view);
  };

  const [items, setItems] = useState([]);

  const setLocalStorage = () => {
    const Cartitems = JSON.parse(localStorage.getItem("cart"));
    Cartitems.length > 0 && setItems(Cartitems);
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(items));
  }, [items]);

  console.log(items);

  const addToCart = (name, image) => {
    setItems((prevState) => [...prevState, { name, image }]);
  };

  function removeFromCart(arr, name) {
    const objWithIdIndex = arr.findIndex((obj) => obj.name === name);
    arr.splice(objWithIdIndex, 1);

    setItems([...items]);
  }

  const clearCart = () => {
    setItems([]);
    handleTshirtSize("default");
  };

  return (
    <CartContext.Provider
      value={{
        items,
        setItems,
        addToCart,
        removeFromCart,
        clearCart,
        showHideCart,
        showCart,
        setLocalStorage,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}

export default CartContext;
