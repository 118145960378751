import Logo from "../../images/andopen_logo.svg";

import { Link } from "react-router-dom";

import {
  StyledFooter,
  PoweredBy,
  Privacy,
  FooterText,
  FooterLogo,
  FooterDot,
} from "./styles";

const Footer = () => {
  return (
    <StyledFooter>
      <a href="https://andopen.co/" target="blank">
        <PoweredBy>
          <FooterLogo src={Logo} />
          <FooterText>Powered by &Open</FooterText>
        </PoweredBy>
      </a>
      <a href="https://andopen.co/privacy" target="blank">
        <Privacy>
          <FooterDot>·</FooterDot>
          <FooterText>Privacy Policy</FooterText>
        </Privacy>
      </a>
    </StyledFooter>
  );
};

export default Footer;
