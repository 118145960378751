import styled from "styled-components";
import { StyledH2, StyledP } from "../../styles";

export const StyledSubmittedFormContainer = styled.div`
  padding: 24px;
  background: #fff;
  border-radius: 4px;
  width: 49.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 100%;
    margin-top: 16px;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledCAddressTitle = styled(StyledH2)`
  font-family: "Tines Bold";
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
`;

export const StyledAddressP = styled(StyledP)`
  line-height: 20px;
`;

export const StyledContact = styled(StyledP)`
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
`;

export const StyledSpan = styled(StyledContact)`
  font-family: "Tines Bold";
  display: inline;
`;

export const StyledHr = styled.hr`
  opacity: 0.4;
`;
