import Lottie from "lottie-react";
import * as welcomAnimation from "../../data/reduced_animation.json";

import { StyledLottieContainer } from "./styles";

const WelcomeAnimation = () => {
  return (
    <StyledLottieContainer>
      <Lottie animationData={welcomAnimation} loop={false} />
    </StyledLottieContainer>
  );
};

export default WelcomeAnimation;
