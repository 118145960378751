import { useEffect, useContext } from "react";
import CartContext from "../CartContext";

import { Link } from "react-router-dom";
import ProductCardInfo from "../data/productcardinfo";

import Cart from "../components/Cart";
import CartCounter from "../components/CartCounter";
import ProductCard from "../components/ProductCard";

import {
  StyledContainer,
  StyledH1,
  StyledP,
  StyledAddressSecondaryButton,
  StyledSecondaryButtonDisabled,
  StyledMB8,
} from "../styles";

import styled from "styled-components";

export const StyledPageContainer = styled(StyledContainer)`
  padding: 0 40px;
  margin: 0 auto;
  width: 1440px;
  max-width: 100%;

  @media (max-width: 600px) {
    padding: 0 24px;
    margin-top: 120px;
  }
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 1440px) {
    width: 90%;
    margin: 0 auto;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
`;

export const StledSub = styled(StyledP)`
  @media (max-width: 1200px) {
    margin-bottom: 16px;
  }
`;

export const StyledGiftContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(276px, 1fr));
  gap: 16px;
  align-items: center;
  margin-top: 32px;

  @media (max-width: 1440px) {
    width: 90%;
    margin: 16px auto;
  }

  @media (max-width: 1200px) {
    margin-top: 32px;
    width: 100%;
  }
`;

const GiftSelection = ({ tshirtSize }) => {
  const { items, showCart, setLocalStorage } = useContext(CartContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLocalStorage();
  }, []);

  useEffect(() => {
    setLocalStorage();
  }, [tshirtSize]);

  return (
    <StyledPageContainer>
      <Cart />

      <StyledTitleContainer>
        <div>
          <StyledH1>But wait ... there's more</StyledH1>
          <StyledMB8 />
          <StledSub>
            You can add additional items to your onboarding pack, choose one,
            two or all of them.
          </StledSub>
        </div>
        {items.length === 1 ? (
          <Link to="/address">
            <StyledAddressSecondaryButton>
              No thanks, just the sweater
            </StyledAddressSecondaryButton>
          </Link>
        ) : (
          <StyledSecondaryButtonDisabled disabled>
            No thanks, just the sweater
          </StyledSecondaryButtonDisabled>
        )}
      </StyledTitleContainer>
      <StyledGiftContainer>
        {ProductCardInfo.map((card) => (
          <ProductCard ProductInfo={card} />
        ))}
      </StyledGiftContainer>
    </StyledPageContainer>
  );
};

export default GiftSelection;
