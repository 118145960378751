import { useContext } from "react";
import CartContext from "../../CartContext";

import GiftImage from "../../images/giftbox.svg";

import {
  StyledCartIcon,
  StyledCartNumber,
  StyledCartIconImage,
} from "./styles";

const CartCounter = ({ selectionPage }) => {
  const { items, showHideCart, showCart } = useContext(CartContext);
  return (
    <StyledCartIcon
      $selectionPage={selectionPage}
      onClick={() => showHideCart(!showCart)}
    >
      <StyledCartNumber>{items.length}</StyledCartNumber>
      <StyledCartIconImage
        alt="close"
        src={GiftImage}
        style={{ width: "18px" }}
        // onClick={() => showHideCart(false)}
      />
    </StyledCartIcon>
  );
};

export default CartCounter;
