import CartContext from "../../CartContext";
import { useContext } from "react";

import Logo from "../../images/intercom_logo.svg";

import { StyledHeader, StyledLogo } from "./styles";

import { StyledContainer } from "../../styles";
import { Link } from "react-router-dom";

const Header = () => {
  const { items, clearCart } = useContext(CartContext);
  return (
    <StyledHeader>
      <Link exact to="/">
        <StyledLogo alt="logo" src={Logo} onClick={() => clearCart()} />
      </Link>
    </StyledHeader>
  );
};

export default Header;
