import styled from "styled-components";

export const StyledSizeGuide = styled.div``;

export const StyledTable = styled.table`
  border-radius: 24px;
  width: 100%;
`;

export const StyledTd = styled.td`
  padding: 6px 12px;
  border: 1px solid #081d34;
`;

export const StyledTh = styled.td`
  padding: 6px 12px;
  border: 1px solid #081d34;
`;
