import styled from "styled-components";
import { StyledP } from "../../styles";

export const StyledProductCardContainer = styled.div`
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.12);
`;

export const StyledProductCardImg = styled.img`
  width: 100%;
  min-height: 240px;
  max-height: 320px;
  object-fit: contain;
  background: #c4e0fd;
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 1440px) {
    max-height: 240px;
  }
`;

export const StyledCardInfo = styled.div`
  padding: 24px;
`;

export const StyledCardContent = styled.div`
  margin-bottom: 48px;
  min-height: 110px; /* overrides height and max-height */
  max-height: 110px;

  @media (max-width: 1440px) {
    margin-bottom: 24px;
  }
`;

export const StyledCardTitle = styled.h3`
  font-family: "Tines Bold";
  font-size: 32px;
  margin-bottom: 24px;

  @media (max-width: 1440px) {
    font-size: 24px;
    margin-bottom: 16px;
  }
`;

export const StyledCardDesc = styled(StyledP)`
  @media (max-width: 1440px) {
    font-size: 14px;
    line-height: 20px;
  }
`;
