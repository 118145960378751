import styled from "styled-components";
import { StyledSecondaryButton } from "../../styles";

export const StyledCartCard = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  width: 100%;
  height: auto;
  border: 1px solid #f1f1f1;
`;

export const StyledImage = styled.img`
  background: ${({ $addressPage }) => ($addressPage ? "#f1f1f1" : "#C4E0FD")};
  height: ${({ $addressPage }) => ($addressPage ? "80px" : "64px")};
  object-fit: contain;
`;

export const StyledTitle = styled.h4`
  font-size: ${({ $addressPage }) => ($addressPage ? "16px" : "14px")};
  margin-left: 12px;
`;

export const StyledRemove = styled(StyledSecondaryButton)`
  font-size: 12px;
  padding: 4px;
  margin-bottom: 8px;
`;
