import {
  StyledSubmittedFormContainer,
  StyledCAddressTitle,
  StyledAddressP,
  StyledFlex,
  StyledContact,
  StyledSpan,
  StyledHr,
} from "./styles";
import { StyledH2, StyledP, StyledMB16 } from "../../styles";

const SubmittedAddress = ({ formInfo, hasSubmitted }) => {
  const {
    firstname,
    lastname,
    addresslineone,
    addresslinetwo,
    city,
    provincestateregion,
    zippostcode,
    phonenumber,
    country,
    email,
    gifts,
  } = formInfo;
  return (
    <StyledSubmittedFormContainer>
      <div>
        <StyledCAddressTitle>
          We'll send your gift to the following adddress:
        </StyledCAddressTitle>
        <StyledAddressP>
          {firstname} {lastname},
        </StyledAddressP>
        <StyledAddressP>{addresslineone},</StyledAddressP>
        {addresslinetwo && <StyledAddressP>{addresslinetwo},</StyledAddressP>}
        <StyledAddressP>{city},</StyledAddressP>
        <StyledAddressP>{provincestateregion},</StyledAddressP>
        <StyledAddressP>{zippostcode},</StyledAddressP>
        <StyledAddressP>{phonenumber},</StyledAddressP>
        <StyledAddressP>{country}</StyledAddressP>
        <StyledAddressP>{email}</StyledAddressP>
      </div>

      <StyledContact>
        <StyledHr />
        Need to update your address? Contact the{" "}
        <StyledSpan>WE team</StyledSpan> through the Messenger
      </StyledContact>
    </StyledSubmittedFormContainer>
  );
};

export default SubmittedAddress;
