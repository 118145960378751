import { useContext, useEffect, useState } from "react";
import CartContext from "../CartContext";

import { Link, useParams } from "react-router-dom";
import TshirtImage from "../images/sweater_3.png";
import DropdownIcon from "../images/dropdown.svg";
import SizeGuide from "../components/SizeGuide";

import styled from "styled-components";
import {
  StyledH1,
  StyledP,
  StyledContainer,
  StyledPrimaryButton,
  StyledPrimaryButtonDisabled,
  StyledMB8,
  StyledMB16,
  StyledMB24,
} from "../styles";

import { StyledSelect } from "../components/Form/styles";

export const StyledTshirtPage = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 64px;

  @media (max-width: 1440px) {
    justify-content: center;
    margin-top: 16px;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const StyledTshirtImage = styled.div`
  background: #c4e0fd;
  background-image: url(${TshirtImage});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 400px;
  width: 614px;
  height: 614px;
  margin-right: 48px;
  border-radius: 16px;

  @media (max-width: 1440px) {
    width: 520px;
    height: 520px;
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    height: 400px;
  }
`;

export const StyledContent = styled.div`
  width: 50%;
  margin-top: 32px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    margin-bottom: 80px;
  }
`;

export const StyledTshirtSelect = styled(StyledSelect)`
  font-size: 16px;
  padding: 20px 24px;
  margin-top: 32px;
`;

const TshirtPage = ({ tshirtSize, handleTshirtSize }) => {
  const { items, setItems, clearCart, addToCart } = useContext(CartContext);

  useEffect(() => {
    setItems([]);
  }, []);

  const [sizeGuide, setSizeGuide] = useState(false);
  const showSizeGuide = () => {
    setSizeGuide(!sizeGuide);
  };

  return (
    <StyledContainer>
      <StyledTshirtPage>
        <StyledTshirtImage alt="t-shirt" src={TshirtImage} />
        <StyledContent>
          <StyledH1>Sweater</StyledH1>
          <StyledMB24 />
          <StyledP>
            100% USA-grown cotton, created by a woman and minority-owned
            business.
          </StyledP>
          <StyledMB16 />
          <StyledP>
            Please note the sweater is a slightly oversized fit. You can view
            the size guide and make your choice below. Reach out to the WE team
            through the Messenger if you have any questions.
          </StyledP>
          <StyledMB8 />

          <StyledP
            onClick={() => showSizeGuide()}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            {sizeGuide ? "Hide size guide" : "Show size guide"}
          </StyledP>

          <StyledMB24 />

          {sizeGuide && <SizeGuide />}

          <div>
            <StyledTshirtSelect
              onChange={(e) => {
                handleTshirtSize(e.target.value);
              }}
              defaultValue={tshirtSize}
              dropdownIcon={DropdownIcon}
            >
              <option value="default" disabled>
                Select your option
              </option>
              <option value="XS">XS</option>
              <option value="S">S</option>
              {/* <option value="M">M</option> 
              <option value="L">L</option>*/}
              <option value="XL">XL</option>
              <option value="XXL">XXL</option>
              <option value="XXXL">XXXL</option>
            </StyledTshirtSelect>
            <StyledMB16 />

            {tshirtSize !== "default" ? (
              <Link to="/gift-selection">
                <StyledPrimaryButton
                  onClick={() => {
                    clearCart();
                    addToCart(`Sweater, size:${tshirtSize}`, TshirtImage);
                  }}
                >
                  Next
                </StyledPrimaryButton>
              </Link>
            ) : (
              <StyledPrimaryButtonDisabled disabled>
                Next
              </StyledPrimaryButtonDisabled>
            )}
          </div>
        </StyledContent>
      </StyledTshirtPage>
    </StyledContainer>
  );
};

export default TshirtPage;
