import { Link } from "react-router-dom";

import { useContext } from "react";
import CartContext from "../../CartContext";

import CartCard from "../CartCard";

import CheckOutImage from "../../images/checkout.png";

import {
  StyledChosenGifts,
  StyledChosenGiftsTitle,
  StyledChosenGiftsButton,
} from "./styles";

const ConfirmationCart = ({ hasSubmitted }) => {
  const { items, clearCart } = useContext(CartContext);
  return (
    <>
      {items.length > 0 && (
        <StyledChosenGifts
          backgroundImage={CheckOutImage}
          hasSubmitted={hasSubmitted}
        >
          <StyledChosenGiftsTitle>Your giftpack</StyledChosenGiftsTitle>
          {items.map((item) => (
            <CartCard name={item.name} image={item.image} addressPage />
          ))}
          <Link to="/tshirt-selection">
            {!hasSubmitted && (
              <StyledChosenGiftsButton
                onClick={() => {
                  clearCart();
                }}
              >
                Change selection
              </StyledChosenGiftsButton>
            )}
          </Link>
        </StyledChosenGifts>
      )}
    </>
  );
};

export default ConfirmationCart;
