import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import WelcomeAnimation from "../components/WelcomeAnimation";

import styled from "styled-components";
import {
  StyledH1,
  StyledH2,
  StyledMB24,
  StyledPrimaryButton,
  StyledContainer,
  StyledMT64,
} from "../styles";

export const StyledContent = styled.div`
  max-width: 70%;

  @media (max-width: 1000px) {
    max-width: 80%;
  }

  @media (max-width: 600px) {
    max-width: 100%;
  }
`;

export const StyledLandingContainer = styled(StyledContainer)`
  margin-left: 200px;

  @media (max-width: 1440px) {
    margin-left: 0px;
    padding: 0 80px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 600px) {
    padding: 0 16px;
    margin-top: 80px;
  }
`;

export const StyledLandingButton = styled(StyledPrimaryButton)`
  width: auto;
  &:hover {
    color: #1e5bf6;
    background: #fff;
  }
`;

export const StyledLottieContainer = styled.div`
  width: 800px;
  z-index: -1;
  position: absolute;
  bottom: 24px;
  right: 120px;

  @media (max-width: 1440px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    width: 50%;
    bottom: 0;
    right: 0;
  }
`;

const Landing = ({ firstName }) => {
  return (
    <>
      <StyledMT64 />
      <StyledLandingContainer>
        <StyledContent>
          {firstName ? (
            <StyledH1>Welcome to the team {` ${firstName}`}!</StyledH1>
          ) : (
            <StyledH1>Welcome to the team!</StyledH1>
          )}

          <StyledMB24 />
          <StyledH2>Here's a little gift to get you started.</StyledH2>
          <StyledMB24 />
          <Link to="/tshirt-selection">
            <StyledLandingButton>Get my gift</StyledLandingButton>
          </Link>
        </StyledContent>

        <WelcomeAnimation />
      </StyledLandingContainer>
    </>
  );
};

export default Landing;
