import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle` 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
    color:#081d34;
  font-size: 16px;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  font-family: "Tines Normal",Arial,sans-serif;
  font-size: 10px;
  background: #fff;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

img, iframe {
  vertical-align: bottom;
  max-width: 100%;
}

input, textarea, select {
  font: inherit;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

a {
  text-decoration:none;
  color:#333;
}

`;

export const StyledH1 = styled.h1`
  font-family: "Tines Black", Arial, sans-serif;
  font-size: 64px;

  @media (max-width: 1440px) {
    font-size: 48px;
  }

  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

export const StyledH2 = styled.h2`
  font-size: 24px;
  line-height: 30px;

  @media (max-width: 1440px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StyledP = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const StyledContainer = styled.div`
  max-width: 1440px;
  padding: 0 40px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    padding: 0 120px;
  }

  @media (max-width: 1000px) {
    padding: 0 80px;
  }

  @media (max-width: 1000px) {
    padding: 0 32px;
  }

  @media (max-width: 600px) {
    padding: 0 16px;
    margin-top: 80px;
  }
`;

export const StyledFlexWrapper = styled.div`
  min-height: 75vh;
  display: flex;
  flex-direction: column;

  @media (max-width: 1440px) {
    min-height: 70vh;
  }

  @media (max-width: 1000px) {
    min-height: 75vh;
  }

  @media (max-width: 600px) {
    min-height: 90vh;
  }
`;

export const StyledPrimaryButton = styled.button`
  font-family: "Tines Bold", Arial, sans-serif;
  font-size: 16px;
  padding: 16px 24px;
  border-radius: 1000px;
  background: #1e5bf6;
  color: #fff;
  border: 2px solid #1e5bf6;
  width: 100%;
  cursor: pointer;
  transition: 0.1s linear;

  &:hover {
    color: #1e5bf6;
    background: #fff;
  }
`;

export const StyledPrimaryButtonDisabled = styled(StyledPrimaryButton)`
  background: #f1f1f1;
  color: rgba(54, 53, 53, 0.5);
  border: none;
  cursor: auto;

  &:hover {
    background: #f1f1f1;
    color: rgba(54, 53, 53, 0.5);
    border: none;
    cursor: auto;
  }
`;

export const StyledSecondaryButton = styled(StyledPrimaryButton)`
  background: #fff;
  color: #000;
  border: 1px solid #000;
  cursor: pointer;

  &:hover {
    color: #fff;
    background: #081d34;
  }
`;

export const StyledSecondaryButtonDisabled = styled(StyledSecondaryButton)`
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.3);
  border: 1px solid #f1f1f1;
  width: auto;
  cursor: auto;
  &:hover {
    color: rgba(0, 0, 0, 0.3);
    background: #f1f1f1;
  }
`;

export const StyledAddressButton = styled(StyledPrimaryButton)`
  width: auto;
  cursor: pointer;
`;

export const StyledAddressSecondaryButton = styled(StyledSecondaryButton)`
  width: auto;
  cursor: pointer;
`;

// Margins
export const StyledMB64 = styled.div`
  margin-bottom: 64px;
`;

export const StyledMB24 = styled.div`
  margin-bottom: 24px;
`;

export const StyledMB16 = styled.div`
  margin-bottom: 16px;
`;

export const StyledMB8 = styled.div`
  margin-bottom: 8px;
`;

export const StyledMT64 = styled.div`
  margin-top: 100px;
  @media (max-width: 1440px) {
    margin-top: 64px;
  }
`;
