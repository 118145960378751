import styled from "styled-components";
import { StyledPrimaryButton, StyledP, StyledH2 } from "../../styles";

export const StyledCartContainer = styled.div`
  width: ${(props) => (props.showCart ? "280px" : "0")};
  position: fixed;
  padding: ${(props) => (props.showCart ? "48px 24px 24px 24px" : "0")};
  box-shadow: ${(props) =>
    props.showCart ? "-2px 4px 12px rgba(0, 0, 0, 0.12)" : ""};
  top: 24px;
  right: 24px;
  background: #fff;

  border-radius: 8px;
  z-index: 1000;

  @media (max-width: 600px) {
    top: 0px;
    right: 0px;
  }
`;

export const CartButton = styled(StyledPrimaryButton)`
  font-family: "Tines Bold";
  margin-top: 24px;
  padding: 8px;
  border: 2px solid #1e5bf6;
`;

export const StyledTitle = styled(StyledH2)`
  font-family: "Tines Bold";
  font-size: 16px;
  line-height: 16px;
`;

export const StyledSub = styled(StyledP)`
  font-size: 12px;
`;

export const StyledCartModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  min-height: 100vh;

  background: rgba(8, 29, 52, 0.2);
`;
