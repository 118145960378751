import { useContext, useEffect, useState } from "react";
import CartContext from "../CartContext";
import CheckOutImage from "../images/checkout.png";

import Form from "../components/Form";
import ConfirmationCart from "../components/ConfirmationCart";
import SubmittedAddress from "../components/SubmittedAddress";

import styled from "styled-components";
import {
  StyledContainer,
  StyledH1,
  StyledMB16,
  StyledMB8,
  StyledP,
  StyledPrimaryButton,
} from "../styles";

import { StyledSelect } from "../components/Form/styles";

import { StyledTitleContainer } from "../pages/giftSelection";

export const StyledAddressContainer = styled(StyledContainer)`
  width: 1440px;
  max-width: 100%;

  @media (max-width: 1440px) {
    margin-top: 24px;
  }

  @media (max-width: 600px) {
    margin-top: 120px;
  }
`;

export const StyledFormContainer = styled.div`
  width: 55%;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 48px 0;
  }
`;

export const StyledFormTitle = styled(StyledH1)`
  margin-bottom: 16px;
  @media (max-width: 1440px) {
    font-size: 40px;
  }
`;

export const StyledFlex = styled.div`
  display: flex;
  margin-top: 40px;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export const StyledSubmittedContainer = styled(StyledFlex)`
  padding: 24px;
  border-radius: 8px;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  background-image: url(${CheckOutImage});
  margin-left: auto;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

export const StyledSubmittedTitles = styled(StyledTitleContainer)`
  width: 100%;
`;

const AddressPage = ({ tshirtSize, firstName, lastName }) => {
  const { items, setLocalStorage } = useContext(CartContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLocalStorage();
  }, []);

  const [formInfo, setFormInfo] = useState([]);
  const handleFormInfo = (value) => {
    setFormInfo(value);
  };

  const [hasSubmitted, setSubmitted] = useState(false);
  const handleSubmitted = (value) => {
    setSubmitted(value);
  };

  return (
    <StyledAddressContainer>
      {!hasSubmitted ? (
        <>
          <StyledFormTitle>Your address please</StyledFormTitle>
          <StyledP>
            Happy with your gift selection? Go ahead and enter your address.
          </StyledP>
        </>
      ) : (
        <StyledSubmittedTitles>
          <div>
            <StyledFormTitle> Congratulations!</StyledFormTitle>
            <StyledP>
              Your gift will be with you shortly! In the meantime, why not head
              over to our site?
            </StyledP>
          </div>

          <a href="https://www.intercom.com/" target="_blank" rel="noreferrer">
            <StyledPrimaryButton style={{ width: "auto", marginTop: "32px" }}>
              Go to Intercom
            </StyledPrimaryButton>
          </a>
        </StyledSubmittedTitles>
      )}

      <StyledMB16 />
      {!hasSubmitted ? (
        <StyledFlex>
          <StyledFormContainer>
            <Form
              items={items}
              tshirtSize={tshirtSize}
              handleSubmitted={handleSubmitted}
              handleFormInfo={handleFormInfo}
              firstName={firstName}
              lastName={lastName}
            />
          </StyledFormContainer>
          <ConfirmationCart hasSubmitted={hasSubmitted} />
        </StyledFlex>
      ) : (
        <>
          <StyledSubmittedContainer>
            <SubmittedAddress formInfo={formInfo} hasSubmitted={hasSubmitted} />
            <ConfirmationCart hasSubmitted={hasSubmitted} />
          </StyledSubmittedContainer>
        </>
      )}
    </StyledAddressContainer>
  );
};

export default AddressPage;
