import { createGlobalStyle } from "styled-components";

import TinesNormal from "../fonts/tt_norms_pro_normal.otf";
import TinesBold from "../fonts/tt_norms_pro_bold.otf";
import TinesBlack from "../fonts/tt_norms_pro_black.otf";

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'Tines Normal';
      src: url(${TinesNormal});
    };

    @font-face {
    font-family: 'Tines Bold';
      src: url(${TinesBold});
    };

    @font-face {
        font-family: 'Tines Black';
      src: url(${TinesBlack});
    };
`;
