import { useContext } from "react";
import CartContext from "../../CartContext";

import Image from "../../images/bag.png";

import {
  StyledCartCard,
  StyledTitle,
  StyledImage,
  StyledRemove,
} from "./styles";

const CartCard = ({ name, image, addressPage }) => {
  return (
    <StyledCartCard $addressPage={addressPage}>
      <StyledImage $addressPage={addressPage} src={image} />
      <StyledTitle $addressPage={addressPage}>{name}</StyledTitle>
    </StyledCartCard>
  );
};

export default CartCard;
