import { Link } from "react-router-dom";

import { useState, useContext, useEffect } from "react";
import CartContext from "../../CartContext";
import CartCard from "../CartCard";
import CartCounter from "../CartCounter";

import {
  StyledCartContainer,
  CartButton,
  StyledSub,
  StyledTitle,
  StyledCartModal,
} from "./styles";

import CloseImage from "../../images/close.svg";

const Cart = () => {
  const { items, showHideCart, showCart } = useContext(CartContext);

  const [isMobile, setMobile] = useState(window.innerWidth < 600);

  const updateMedia = () => {
    setMobile(window.innerWidth < 600);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <>
      {isMobile && showCart && (
        <StyledCartModal onClick={() => showHideCart(false)} />
      )}
      <StyledCartContainer showCart={showCart}>
        <CartCounter />

        {showCart && (
          <>
            <StyledTitle>Your gift pack</StyledTitle>
            <StyledSub>Add as many items as you like</StyledSub>
            {items.map((item) => (
              <CartCard
                name={item.name}
                tshirtSize={item.tshirtSize}
                image={item.image}
              />
            ))}
            <Link to="/address">
              {items.length > 1 ? <CartButton>Checkout</CartButton> : ""}
            </Link>
          </>
        )}
      </StyledCartContainer>
    </>
  );
};

export default Cart;
