import styled from "styled-components";

export const StyledForm = styled.form`
  width: 100%;
`;

export const StyledField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`;

export const StyledInput = styled.input`
  font-size: 24px;
  padding: 12px 24px;
  border: 1px solid #000;
  border-radius: 1000px;
  color: #000;
  width: 100%;
  margin-bottom: 4px;

  ::placeholder {
    color: #081d34;
  }

  @media (max-width: 1440px) {
    font-size: 20px;
  }
`;

export const StyledHalfField = styled(StyledField)`
  max-width: 49.5%;

  @media (max-width: 600px) {
    min-width: 100%;
  }
`;

export const StyledError = styled.div`
  font-size: 12px;
  color: #ff645f;
`;

export const StyledPhoneText = styled.p`
  font-size: 12px;
`;

export const StyledFlex = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledSelect = styled.select`
  cursor: pointer;
  font-size: 24px;
  padding: 14px 24px;
  border: 1px solid #000;
  border-radius: 1000px;
  color: #000;
  width: 100%;
  margin-bottom: 4px;
  background-color: #fff;
  background-image: ${(props) => `url(${props.dropdownIcon})`};
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
  appearance: none;
  background-position-x: ${({ $address }) => ($address ? "97%" : "96%")};

  @media (max-width: 1440px) {
    font-size: 20px;
  }
`;
