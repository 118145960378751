import styled from "styled-components";

import { StyledH2, StyledSecondaryButton } from "../../styles";

export const StyledChosenGifts = styled.div`
  box-shadow: ${(props) =>
    props.hasSubmitted ? "" : " 4px 4px 12px rgba(0, 0, 0, 0.12)"};

  padding: ${(props) => (props.hasSubmitted ? "8px 0 " : "24px")};
  width: ${(props) => (props.hasSubmitted ? "49.5%" : "30%")};
  height: 100%;
  border-radius: 8px;
  float: left;
  margin-left: ${(props) => (props.hasSubmitted ? "0" : "80px")};
  background: ${(props) => (props.hasSubmitted ? "none" : "#cbdffa")};
  background-image: ${(props) => `url(${props.backgroundImage})`};
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: ${(props) =>
    props.hasSubmitted ? "none" : `url(${props.backgroundImage})`};

  @media (max-width: 1000px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const StyledChosenGiftsTitle = styled(StyledH2)`
  font-family: "Tines Bold";
  font-size: 16px;
`;

export const StyledChosenGiftsButton = styled(StyledSecondaryButton)`
  width: auto;
  padding: 8px 16px;
  margin-top: 16px;
  font-size: 12px;
  background: #cbdffa;
`;
