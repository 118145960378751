import styled from "styled-components";

import { StyledP } from "../../styles";

export const StyledFooter = styled.header`
  padding: 48px 80px;
  display: flex;

  @media (max-width: 1000px) {
    padding: 32px;
  }

  @media (max-width: 600px) {
    padding: 24px;
  }
`;

export const FooterText = styled(StyledP)`
  font-size: 12px;
  margin-left: 4px;
`;

export const FooterLogo = styled.img`
  height: 14px;
`;

export const PoweredBy = styled.div`
  display: flex;
  align-items: center;
`;

export const Privacy = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterDot = styled.span`
  padding: 0 0.4rem;
`;
