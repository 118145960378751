import bag from "../images/bag.png";
import bagBack from "../images/bag_back.png";
import notebook from "../images/notebook.png";
import notebookBack from "../images/notebook_back.png";
import waterbottle from "../images/updatedwaterbottle.png";
import waterBottlekBack from "../images/waterbottle_back.png";

const productCardData = [
  {
    image: bag,
    backgroundImage: bagBack,
    title: "Hajo Backpack",
    sub: "This sleek roll-top backpack is perfect for cycling to work and is ethically made to last from recycled materials.",
    id: 1,
  },
  {
    image: notebook,
    backgroundImage: notebookBack,
    title: "Karst Notebook",
    sub: "A strong as stone (waterproof) notebook made from waste construction material. For list making, doodline or getting your thoughts on paper.",
    id: 2,
  },
  {
    image: waterbottle,
    backgroundImage: waterBottlekBack,
    title: "Ocean Bottle",
    sub: "An insulated leak-proof reusable bottle that helps keep plastic out of the ocean. Great for water, coffee, or cocktails. That's the tea!",
    id: 3,
  },
];

export default productCardData;
