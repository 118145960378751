import ProductCardButton from "../ProductCardButton";

import {
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledP,
} from "../../styles";
import {
  StyledProductCardContainer,
  StyledProductCardImg,
  StyledCardInfo,
  StyledCardContent,
  StyledCardTitle,
  StyledCardDesc,
} from "./styles";

import CartContext from "../../CartContext";
import { useContext } from "react";

const ProductCard = ({ ProductInfo }) => {
  const { items, addToCart, removeFromCart, showHideCart } =
    useContext(CartContext);

  return (
    <StyledProductCardContainer>
      <StyledProductCardImg
        src={ProductInfo.image}
        backgroundImage={ProductInfo.backgroundImage}
      />
      <StyledCardInfo>
        <StyledCardContent>
          <StyledCardTitle>{ProductInfo.title}</StyledCardTitle>
          <StyledCardDesc>{ProductInfo.sub}</StyledCardDesc>
        </StyledCardContent>

        {!items.find(({ name }) => name === ProductInfo.title) ? (
          <StyledPrimaryButton
            onClick={(event) => {
              addToCart(ProductInfo.title, ProductInfo.image);
              showHideCart(true);
            }}
          >
            Add to gift box
          </StyledPrimaryButton>
        ) : (
          <StyledSecondaryButton
            onClick={() => {
              removeFromCart(items, ProductInfo.title);
            }}
          >
            Remove from gift box
          </StyledSecondaryButton>
        )}
      </StyledCardInfo>
    </StyledProductCardContainer>
  );
};

export default ProductCard;
