import { StyledSizeGuide, StyledTable, StyledTd, StyledTh } from "./styles";
import { StyledMB8 } from "../../styles";

const SizeGuide = () => {
  return (
    <StyledSizeGuide>
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>Sizes</StyledTh>
            <StyledTh>Half Chest</StyledTh>
            <StyledTh>Body Length </StyledTh>
            <StyledTh>Sleeve Length </StyledTh>
          </tr>
        </thead>
        <tbody>
          <tr>
            <StyledTd>XS</StyledTd>
            <StyledTd>54cm</StyledTd>
            <StyledTd>63cm</StyledTd>
            <StyledTd>758.5cm</StyledTd>
          </tr>
          <tr>
            <StyledTd>S</StyledTd>
            <StyledTd>56.5cm</StyledTd>
            <StyledTd>66cm</StyledTd>
            <StyledTd>61.5cm</StyledTd>
          </tr>
          {/* <tr>
            <StyledTd>M</StyledTd>
            <StyledTd>59cm</StyledTd>
            <StyledTd>70cm</StyledTd>
            <StyledTd>64cm</StyledTd>
          </tr> 
          <tr>
            <StyledTd>L</StyledTd>
            <StyledTd>62cm</StyledTd>
            <StyledTd>72cm</StyledTd>
            <StyledTd>62.5cm</StyledTd>
          </tr>
          */}
          <tr>
            <StyledTd>XL</StyledTd>
            <StyledTd>65cm</StyledTd>
            <StyledTd>74cm</StyledTd>
            <StyledTd>67cm</StyledTd>
          </tr>
          <tr>
            <StyledTd>XXL</StyledTd>
            <StyledTd>68cm</StyledTd>
            <StyledTd>76cm</StyledTd>
            <StyledTd>68.5cm</StyledTd>
          </tr>
          <tr>
            <StyledTd>XXXL</StyledTd>
            <StyledTd>71cm</StyledTd>
            <StyledTd>79cm</StyledTd>
            <StyledTd>68.5cm</StyledTd>
          </tr>
        </tbody>
      </StyledTable>
    </StyledSizeGuide>
  );
};

export default SizeGuide;
