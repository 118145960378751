import styled from "styled-components";

export const StyledCartNumber = styled.span`
  width: 20px;
  height: 20px;
  font-size: 12px;
  position: absolute;
  top: -4px;
  right: -4px;
  background: #0057ff;
  color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
`;

export const StyledCartIcon = styled.div`
  width: 48px;
  height: 48px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 16px;

  cursor: pointer;

  &:hover {
    ${StyledCartNumber} {
      background-color: #081d34;
    }
  }
`;

export const StyledCartIconImage = styled.img`
  min-width: 100%;
  height: auto;
  margin-bottom: 4px;
`;
