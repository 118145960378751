import styled from "styled-components"

export const StyledLottieContainer = styled.div`
  width: 800px;
  z-index: -1;
  position: absolute;
  bottom: 24px;
  right: 120px;

  @media (max-width: 1440px) {
    width: 40%;
  }

  @media (max-width: 600px) {
    width: 50%;
    bottom: 0;
    right: 0;
  }
`;